import Axios from 'axios'
import axiosRetry from 'axios-retry'

const axios = Axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
    },
    withCredentials: true,
})

axios.interceptors.request.use(function (config) {

    const token = localStorage.getItem('token')
    config.headers.Authorization = token && `Bearer ${token}`
    return config;
})



axiosRetry(axios, {
    retries: 1,
    retryDelay: (retryCount) => {

        //axiosRetry.exponentialDelay
        return retryCount * 5000;
    },
    retryCondition: (error) => [429,500].includes(error.response.status) /*{
        
        //console.log('ERROR_STATUS_CODE', error.response.status, [429,500].includes(error.response.status))
        return [429,500].includes(error.response.status)
    }*/
})

export default axios
