import { React, useEffect } from "react"

const ValidationErrors = ({errors = [], ...props}) => {

    return <>
        {errors.length > 0 && (
            <div className="alert alert-danger" {...props}>
                <div className="text-start">Whoops! Something went wrong.</div>

                <ul>
                    {errors.map(error => (
                        <li key={error}>{error}</li>
                    ))}
                </ul>
            </div>
        )}
    </>
}

export default ValidationErrors