import { React, useState } from "react"
import AuthCard from "../../components/AuthCard"
import AuthSessionStatus from "../../components/AuthSessionStatus"
import ValidationErrors from "../../components/ValidationErrors"
import { useAuth } from "../../hooks/auth"

const ForgetPassword = () => {
    
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [email, setEmail] = useState('')
    const {forgotPassword} = useAuth()

    const handleSubmit = async event => {
        event.preventDefault()

        setIsSubmitting(true)
        forgotPassword({email, setErrors, setStatus, setIsSubmitting})
    }

    return <AuthCard>
        <div className="row">
            <div className="col-sm-12">
                <form onSubmit={handleSubmit}>
                    <h1 className="h3 mb-3 text-center fw-normal">Forget Password</h1>
                    <AuthSessionStatus className="alert alert-success mb-4" status={status} />
                    {!!errors.length && <ValidationErrors errors={errors} />}

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <div className="form-floating">
                                    <input type="email" name="email" id="email" placeholder="E-mail" className="form-control" onChange={(e) => setEmail(e.target.value) } value={email} />
                                    <label htmlFor="email">Email</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <button disabled={isSubmitting || !email} className="w-100 btn btn-primary" type="submit">
                                {isSubmitting && <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>} To Log In
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </AuthCard>
}

export default ForgetPassword