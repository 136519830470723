import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "./partials/Navigation";

export default function AppLayout() {

    return <div className="container-fluid p-0">
        <Navigation />

        <main className="container">
            <Outlet />
        </main>
    </div>
}