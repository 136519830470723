import React, {useEffect, useState} from "react"
import Select from 'react-select'
import CollapsibleCard from "../components/CollapsibleCard"
import useHelpers from "../hooks/helpers"
import {useForm} from "@inertiajs/inertia-react"
import InputErrors from "../components/InputErrors"
import DatePicker from "react-datepicker";
import axios from "../lib/axios";
import { useAuth } from "../hooks/auth"
import IsLoading from "../components/IsLoading"
import { format } from "date-fns";
import ClipboardJS from 'clipboard'

export default function Index() {

    const [base64Required, setBase64Required] = useState(false)

    const [qrcodeBase64, setQrcodeBase64] = useState('')

    const [responseData, setResponseData] = useState({})

    const [processing, setProcessing] = useState(false)

    const { data, setData, post, reset } = useForm({ 
        pid: '',
        fn: '',
        gn: '',
        dob: '',
        type_doc_id: '',
        title_doc: '',
        ref_doc: '',
        transactionId: '',
        quantity: 1,
        amount: 0,
    })

    const [dob, setDob] = useState('')

    const [errors, setErrors] = useState([])

    const {user} = useAuth({middleware: 'auth'})

    const {requiredLabel} = useHelpers()

    const [typesEtimbre, setTypesEtimbre] = useState([])

    const [typeDocs, setTypeDocs] = useState([])

    const [defaultTypeDoc, setDefaultTypeDoc] = useState('')

    const [dateRequest, setDateRequest] = useState(new Date())

    const [typesEtimbreData, setTypesEtimbreData] = useState([])

    useEffect(() => {

        if(qrcodeBase64) {

            new ClipboardJS('#clipboard-btn');
        }
    }, [qrcodeBase64])

    const resetForm = () => {

        setResponseData({})
        setBase64Required(false)
        setQrcodeBase64('')
        setDob('')
        console.log('RESET')
        reset('pid', 'fn', 'gn', 'dob', 'ref_doc', 'transactionId', 'quantity', 'amount')
    }

    useEffect(() => {

        axios.get(`/api/intitules/docs-types/by-group`)
            .then(r => r.data)
            .then(d => {

                const intitule = d.filter(type => type.constante === "LIB_DOC_TYPE_EQUITTANCE")[0]
                setTypeDocs(d.map(type =>{ return {value: type.id, label: type.libelle} }))
                setDefaultTypeDoc({value: intitule.id, label: intitule.libelle})
                setData({
                    ...data,
                    title_doc: intitule.libelle,
                    type_doc_id: intitule.id
                })
            })
            
    }, [])

    const handleChange = (name, value) => {

        setData({
            ...data,
            [name]: value
        })
    }

    const convertToBase64 = async () => {

        /* const img = new Image()
        img.addEventListener("load", function () {

            console.log(loaded)
        }) */
        /* imageToBase64(responseData.image_url)
            .then(response => {

                console.log(response)
                setQrcodeBase64(response)
            }) */
        /* const data = await fetch(responseData.image_url)
        const blob = await data.blob()
        new Promise((resolve) => {

            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {

                setQrcodeBase64(reader.result)
                //(reader.result.replace(/^data:image\/(png|jpg);base64,/, ""));
            }
        }) */
    }

    const handleSubmit = () => {

        setProcessing(true)
        axios.post('/api/qrsign/generate', data)
            .then(r => r.data)
            .then(data => {

                setProcessing(false)
                setResponseData(data)
            })
            .catch(e => {

                setErrors(Object.values(e.response.data.errors).flat())
                setProcessing(false)
            })
    }

    return <div className="row mt-5">
        
        {!!errors.length && <div className="col-md-12">
            <div className="alert alert-danger">
                Whoops, Une erreur, Merci de bien vouloir rempir le formulaire correctement !!!
                <ul>
                    {errors.map((error, key) => <li key={key}>{error}</li>)}
                </ul>
            </div>
        </div>}

        <div className="col-md-8">
            {
                !user ?  <IsLoading /> : <div className="card shadow-sm">
                    <div className="card-body">
                        {!Object.keys(responseData).length ? <div className="row">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        {requiredLabel("title_doc", "Type Doc")}
                                        <Select value={defaultTypeDoc} isDisabled={true} options={typeDocs} onChange={option => {

                                            setData({...data, title_doc: option.libelle})
                                        }} />
                                        {/* <input type="text" name="type_doc_id" id="type_doc_id" value={data.type_doc_id} onChange={e => setData("type_doc_id", e.target.value)} className="form-control" /> */}
                                    </div>
                                </div>
                            
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        {requiredLabel("ref_doc", "Reference Doc")}
                                        <input type="text" name="ref_doc" id="ref_doc" value={data.ref_doc} onChange={e => setData("ref_doc", e.target.value)} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="transactionId">Transaction ID</label>
                                        <input type="text" name="transactionId" id="transactionId" value={data.transactionId} onChange={e => setData("transactionId", e.target.value)} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        {requiredLabel("pid", "Pièce Identité")}
                                        <input type="text" name="pid" id="pid" value={data.pid} onChange={e => setData("pid", e.target.value)} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        {requiredLabel("dob", "Date Naissance")}
                                        {/* <input type="date" name="dob" id="dob" value={data.dob} onChange={e => setData("dob", e.target.value)} className="form-control" /> */}
                                        <DatePicker selected={dob} className="form-control" format="dd-MM-YYYY" onChange={d => {
                                            setDob(d)
                                            setData("dob", format(d, "dd-MM-yyyy"))
                                        }} />
                                    </div>
                                </div>
                            
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        {requiredLabel("fn", "Nom")}
                                        <input type="text" name="fn" id="fn" value={data.fn} onChange={e => setData("fn", e.target.value)} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        {requiredLabel("gn", "Prénoms")}
                                        <input type="text" name="gn" id="gn" value={data.gn} onChange={e => setData("gn", e.target.value)} className="form-control" />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="amount">Prix Unitaire</label>
                                        <input type="number" name="amount" id="amount" value={data.amount} onChange={e => setData("amount", e.target.value)} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="quantity">Quantité</label>
                                        <input type="number" name="quantity" id="quantity" value={data.quantity} onChange={e => setData("quantity", e.target.value)} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-transparent">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type="submit" disabled={processing} className="btn btn-success" onClick={handleSubmit}>
                                            Valider
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div> : <div className="row">
                            <div className="col-md-6 col-sm-12 text-center mb-3">
                                <img id="qrcode_image" src={responseData.image_url} className="img-thumbnail" width="250" />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div>
                                            <label className="form-label" htmlFor="base64_required">
                                                <input type="checkbox" checked={base64Required} onChange={e => {
                                                    
                                                    setBase64Required(!base64Required)
                                                    if(!qrcodeBase64) {

                                                        setQrcodeBase64(responseData.image_base64)
                                                    }
                                                }} /> Mode Base64 ?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <textarea style={{ height: "250px" }} row="30" className="form-control" value={JSON.stringify(responseData, undefined, 4)} disabled />
                            </div>

                            {base64Required && qrcodeBase64 && <div className="col-md-12 text-center mb-3">
                                        <pre id="qrcode_base64" className="bg-light p-2">
                                            <code>{qrcodeBase64}</code></pre>
                                            <button id="clipboard-btn" className="btn btn-sm btn-primary" 
                                                data-clipboard-target="#qrcode_base64"
                                                type="button">Copier!
                                            </button>
                            </div>}

                            <div className="card-footer bg-transparent">
                                <div className="col-md-12 mt-3 text-center">
                                    <button onClick={resetForm} className="btn btn-danger">Réinitialiser!</button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            }
        </div>
    </div>
}