import React from "react"
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import "react-datepicker/dist/react-datepicker.css"
import Index from "./pages/Index"
import AppLayout from "./pages/layouts/AppLayout";
import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import { createRoutesFromElements } from "react-router-dom";
import { Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";

function App() {

  /* const router = createBrowserRouter([
    {
      path: "/",
      element: <Index />
    }
  ]) */

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<AppLayout />}>
        <Route index path="/" element={<Index />} />
        <Route path='/login' element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
      </Route>
    )
  )

  return <RouterProvider router={router} />
}

export default App;
