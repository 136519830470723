import { React, useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import AuthCard from "../../components/AuthCard"
import AuthSessionStatus from "../../components/AuthSessionStatus"
import ValidationErrors from "../../components/ValidationErrors"
import { useAuth } from "../../hooks/auth"

const Login = () => {
    
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const {login} = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/'
    })

    const submitForm = async event => {
        event.preventDefault()

        setIsLoggingIn(true)
        login({username, password, setErrors, setStatus, setIsLoggingIn})
    }

    useEffect(() => {

        if(searchParams.has('reset')) {

            setStatus(atob(searchParams.get('reset')))
        }
    }, [])

    return <AuthCard>
        <div className="row">
            <div className="col-sm-12">
                <form onSubmit={submitForm}>
                    <h1 className="h3 mb-3 fw-normal text-center">Authentication</h1>
                    
                    <AuthSessionStatus className="alert alert-info mb-4" status={status} />
                    {!!errors.length && <ValidationErrors errors={errors} />}

                    <div className="mb-3">
                        <div className="form-floating">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Username" value={username} onChange={event => setUsername(event.target.value)} required />
                            <label htmlFor="floatingInput">Username</label>
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="form-floating">
                            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={event => setPassword(event.target.value)} required />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>
                    </div>

                    <div className="checkbox mb-3 text-start">
                        <label>
                            <input type="checkbox" name="remember" id="remember_me" value="remember-me" /> Remember Me
                        </label>
                    </div>

                    <div className="row g-1">
                        <div className="col">
                            <button disabled={isLoggingIn} className="w-100 btn btn-primary" type="submit">
                                {isLoggingIn && <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>} To Login</button>
                        </div>
                    </div>
                    
                    <div className="row g-1 mt-3">
                        <div className="col text-start">
                            <Link className="btn btn-link w-100" to="/forget-password">Forget Password</Link>
                        </div>
                    </div>
                    {/* <p className="mt-3 text-muted">© 2021–2022</p> */}
                </form>
            </div>
        </div>
    </AuthCard>
}

export default Login