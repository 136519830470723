import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Nav, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";

export default function Navigation() {

    const {user, logout} = useAuth();

    return <Navbar bg="white" expand="lg">
        <Container>
            <Link className="navbar-brand" to="/">
                <img src={'/logoent75.png'} alt='Logo' />
            </Link>

            <Navbar.Toggle aria-controls='navbarScroll' />
            <Navbar.Collapse id="navbarScroll">
                <Nav className="ml-auto" navbarScroll>
                    <Nav.Item>
                        <Link className="nav-link" to="/">Home</Link>
                    </Nav.Item>

                    {user && <NavDropdown title={user.name} id="nav-dropdown">
                        <NavDropdown.Item>
                            <a className="dropdown-item text-primary rounded cursor-pointer" onClick={logout}><i className="bi bi-power"></i>  Logout </a>    
                        </NavDropdown.Item>
                    </NavDropdown>}
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
}