import React from "react";
import {useAuth} from "./auth";

export default function useHelpers() {

    const { user } = useAuth()
    const requiredLabel = (id, label) => {

        return <label className="form-label" htmlFor={id}><abbr className="text-danger" data-id={id} title="Obligatoire">*</abbr> {label}</label>
    }

    const totalPayable = (formData) => {

        let total = +formData.should_pay;
        if(user && user.annuaire) {

            if(formData.send_to_all) {
                
                total *= formData.list_of_usagers.length;
                total += (formData.list_of_usagers.length * +formData.sms_cost)
            } else {

                total *= formData.selected_usagers.length;
                total += (formData.selected_usagers.length * +formData.sms_cost)
            }
        } else {

            total += +formData.sms_cost;
        }
        
        return total
    }

    return {
        requiredLabel,
        totalPayable
    }
}